/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_esigner/helpers'
import { ResetPassword } from './components/ResetPassword'
import {  validateDomainName } from '../../modules/organizations/redux/OrganizationsCRUD'

export function AuthPage() {
  useEffect(() => {     
    var domainName:string = window.location.toString();
    domainName = domainName.replaceAll("http://","").replaceAll("https://","").replaceAll("/auth/login","");
    
    if (domainName != null && domainName != "") {
      validateDomainName("", domainName, 0)
        .then((response) => {
          let resultCode = response.data.responseCode;
          let responseMessage = response.data.responseMessage;
          let responseObject = response.data.responseObject;
          localStorage.clear();

          if (resultCode == 0) {
            localStorage.setItem("organizationCode",  responseObject.organizationId);
            localStorage.setItem("refOrganization",  JSON.stringify(responseObject));
            // console.log("Domain Test Pass =>"+responseObject);
          }
          else { 
            localStorage.setItem("organizationCode",  "-1");
           // console.log("Domain Test Failed => "+responseObject);
            localStorage.setItem("refOrganization",  responseObject);
          }
        })
        .catch((e) => {
          alert(`Error Occured...${e}`)
          localStorage.setItem("organizationCode",  "-1");
          localStorage.setItem("refOrganization",  "");
        })
    }
    else
    {
          alert(`Error: Domain does not exist.`)
          localStorage.setItem("organizationCode",  "-1");
    }

    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='https://www.tendertiger.com/' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/varify-token/:emailId/:token' component={ResetPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://www.certificatetiger.com/' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='https://www.certificatetiger.com/ContactUs.aspx' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='https://www.certificatetiger.com/digital-signature-certificate-register-now.aspx' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
