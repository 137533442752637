import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_esigner/partials'
// import { ProfileDetails } from '../modules/accounts/components/settings/cards/ProfileDetails'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Languages } from '../../_esigner/partials/layout/header-menus/Languages'

// import UserList from '../modules/accounts/UserList'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const OrganizationsPage = lazy(() => import('../modules/organizations/Organizations'))
  const DepartmentPage = lazy(() => import('../modules/departments/Departments'))
  const SubDepartmentPage = lazy(() => import('../modules/departments/Departments'))
  const DesignationPage = lazy(() => import('../modules/designations/Desingations'))
  const UserPage = lazy(() => import('../modules/Users/Users'))
  const UserHomePage = lazy(() => import('../modules/Home/components/UserHome'))
  const UploadDocPage = lazy(() => import('../modules/Home/components/UploadDoc'))
  const pageOfDocPage = lazy(() => import('../modules/envelops/components/PageOfDoc'))
  const DocOfEnvelopePage = lazy(() => import('../modules/envelops/components/DocOfEnvelope'))
  const OnEnvelopePage = lazy(() => import('../modules/envelops/components/OnEnvelope'))
  const ManageInboxPage = lazy(() => import('../modules/Manage/Inbox'))
  const ManageSentPage = lazy(() => import('../modules/Manage/Sent'))
  const ManageDraftsPage = lazy(() => import('../modules/Manage/Drafts'))
  const ManageDeletedPage = lazy(() => import('../modules/Manage/Deleted'))
  const ManageActionReqPage = lazy(() => import('../modules/Manage/ActionReq'))
  const ManageExpiringSoonPage = lazy(() => import('../modules/Manage/ExpiringSoon'))
  const AddRecipientPage = lazy(() => import('../modules/recipient/components/AddRecipient'))
  const ManageSignPage = lazy(() => import('../modules/signs/components/ManageSign'))
  const ImSignerAddRecipientPage = lazy(() => import('../modules/recipient/components/ImSignerAddRecipient'))
  const ManageRolePage = lazy(() => import('../modules/roles/Roles'))
  const RightsPage = lazy(() => import('../modules/Rights/components/Rights'))
  const EnvelopeHistoryPage = lazy(() => import('../modules/EnvelopInformation/EnvelopeHistory'))
  const DragDropePage = lazy(() => import('../modules/envelops/EnvelopeD&D/EnvelopeD&D'));
  const ProfileDetailsPage = lazy(() => import('../modules/ProfileDetails/Components/Profile'));
  const DashbordAdminPage = lazy(() => import('../modules/dashbordAdmin/Admin'));
  const UpdateSignPage = lazy(() => import('../modules/signs/components/UpdateSign'));
  const ProductAdminPage = lazy(() => import('../modules/ProductAdminDashboard/ProductAdmin'));
  const HODPage = lazy(() => import('../modules/DashbordHOD/Hod'));
  const DadhboardManagerPage = lazy(() => import('../modules/dashboardManager/Manager'));
  const Certificate = lazy(() => import('../modules/Certificate/Components/Certificate'));
  const UserProfilePage = lazy(() => import('../modules/userProfile/UserProfile'))
  const ForgotPasswordPage = lazy(() => import('../modules/ForgotPassword/forgotPass'))
  const SavedDSC = lazy(() => import('../modules/Certificate/Components/SavedDsc'));
  const Contacts = lazy(() => import('../modules/Contacts/Contacts'))
  const PreviewDoc = lazy(() => import('../modules/Home/components/PreviewDoc'))
  const passwordChange = lazy(() => import('../modules/userProfile/ChangePassword'))
  const languagesMain = lazy(() => import('../modules/Home/components/Languages'))
  const EnveloperReport = lazy(() => import('../modules/reports/components/ViewReports'))
  const OrganizationView = lazy(() => import('../modules/organizations/Organizations'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/change/password' component={passwordChange} />
        <Route path='/user/forgot_password' component={ForgotPasswordPage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/apps/organizations' component={OrganizationsPage} />
        <Route path='/apps/departments' component={DepartmentPage} />
        <Route path='/apps/sub-departments' component={SubDepartmentPage} />
        <Route path='/apps/designations' component={DesignationPage} />
        <Route path='/apps/users' component={UserPage} />
        {/* Rights */}
        <Route path='/apps/rights' component={RightsPage} />
        <Route path='/apps/roles' component={ManageRolePage} />
        {/* Profile */}
        <Route path='/userDetails' component={UserProfilePage} />

        {/* Dashbord */}
        <Route path='/dashboard1/admin' component={DashbordAdminPage} />
        <Route path='/dashboard1/productAdmin' component={ProductAdminPage} />
        <Route path='/dashboard1/hod' component={HODPage} />
        <Route path='/dashboard1/manager' component={DadhboardManagerPage} />
        {/* Document */}
        <Route path='/home/user' component={UserHomePage} />
        <Route path='/home/docUpload' component={UploadDocPage} />

        {/* Create Enveloppe */}
        <Route path='/envelope/pageOfDoc/:envelopeId' component={pageOfDocPage} />
        {/* <Route path='/envelope/pageOfDoc/:envelopeId' component={pageOfDocPage} /> */}
        <Route path='/envelope/DocOfEnvelope/:envelopeId' component={DocOfEnvelopePage} />
        <Route path='/envelope/OnEnvelope/:envelopeId' component={OnEnvelopePage} />
        <Route path='/envelope/DandD' component={DragDropePage} />

        {/* Envelope Details */}
        <Route path='/envelope/EnvelopeHistory/:envelopeId' component={EnvelopeHistoryPage} />
        {/* Sign */}
        <Route path='/sign/addRecipient/:envelopeId' component={AddRecipientPage} />
        <Route path='/sign/signatureManage' component={ManageSignPage} />
        <Route path='/sign/UpdateSignature' component={UpdateSignPage} />
        <Route path='/sign/signer/addRecipient/:envelopeId' component={ImSignerAddRecipientPage} />
        <Route path='/home/certificate' component={Certificate} />
        <Route path='/home/signer/setup' component={Certificate} />
        <Route path='/home/savedsc' component={SavedDSC} />
        {/* Manage */}
        <Route path='/manage/inbox' component={ManageInboxPage} />
        <Route path='/manage/sent' component={ManageSentPage} />
        <Route path='/manage/draft' component={ManageDraftsPage} />
        <Route path='/manage/deleted' component={ManageDeletedPage} />
        <Route path='/manage/actionRequired' component={ManageActionReqPage} />
        <Route path='/manage/ExpiringSoon' component={ManageExpiringSoonPage} />
        <Route path='/apps/contacts' component={Contacts} />
        <Route path='/apps/preview/:documentId' component={PreviewDoc} />
        <Route path='/apps/languages' component={languagesMain} />
        <Route path='/report/envelopes/' component={EnveloperReport} />
        <Route path='/report/reciepients/' component={EnveloperReport} />
        <Route path='/apps/org/details/:organizationId' component={OrganizationView} />

        {/* <Route path='/users' component={UserList} />  */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

