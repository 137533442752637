import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/api/auth/getToken`
export const LOGIN_URL = `${API_URL}/v1/api/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GENERATE_TOKEN = `${API_URL}/v1/api/auth/generateToken`
export const VALIDATE_TOKEN = `${API_URL}/v1/api/auth/validateToken`
export const RESET_PASSWORD = `${API_URL}/v1/api/auth/resetPassword`

// Server should return AuthModel
export function login(emailAddress: string, password: string, organizationId:number) {
  return axios.post(LOGIN_URL, {
    emailAddress,
    password, organizationId
  })  
}

export function generatePass(emailAddress: string) {
  return axios.post(GENERATE_TOKEN, {
    emailAddress,
  })  
}

export function validateToken(emailAddress: string, token: string) {
  return axios.post(VALIDATE_TOKEN, {
    emailAddress, token
  })  
}

export function resetPassword(emailAddress: string, token: string, password: string)  {
  return axios.post(RESET_PASSWORD, {
    emailAddress, token, password
  })  
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token:string) {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token:token
  })  
}
