import axios from 'axios'

declare module 'axios' {
  export interface AxiosRequestConfig {
    tkc?: string;
    organizationId?: number;
  }
}

const API_URL = process.env.REACT_APP_API_URL

export const GET_ALL_ORGANIZATIONS = `${API_URL}/v1/api/org/get/all`
export const GET_ORGANIZATION_DETAILS = `${API_URL}/v1/api/org/get/byId`
export const ACTIVATE_ORGANIZATION = `${API_URL}/v1/api/org/activate`
export const DEACTIVATE_ORGANIZATION = `${API_URL}/v1/api/org/deactivate`
export const EDIT_ORGANIZATION = `${API_URL}/v1/api/org/edit`
export const ADD_ORGANIZATION = `${API_URL}/v1/api/org/add/new`
export const GET_CIN_NO= `${API_URL}/v1/api/org/get/byPanCinNo`
export const GET_DOMAIN_NAME= `${API_URL}/v1/api/org/get/byDomainName`
export const VALIDATE_DOMAIN_NAME= `${API_URL}/v1/api/org/getOrg/byDomainName`


export function getAllOrganizations(tkc: string) {
  return axios.get(GET_ALL_ORGANIZATIONS, {

  })
}

export function getOrganizationDetails(tkc: string, organizationId: number) {
  return axios.get(GET_ORGANIZATION_DETAILS, {
    organizationId
  })
}

export function markOrganizationAsActive(tkc: string, organizationId:number) {
  return axios.post(ACTIVATE_ORGANIZATION, {
    organizationId
  })
}

export function markOrganizationAsDeactive(tkc: string, organizationId:number) {
  return axios.post(DEACTIVATE_ORGANIZATION, {
    organizationId
  })
}

export function addNewOrganization(tkc: string, organizationName:string,organizationCode:string,description: string, address1: string, address2: string,
  countryId: number, stateId: number, cityId: number,  contactEmail: string, contactPhone1: string, 
  contactPhone2: string, gstCinNo: string, domain:string, serviceMethod: any, serviceType: number, validTillDate: any,
  validFromDate: any, amount: number, usageLimit: number, website: string, userId: number, packageType:number) {
  return axios.post(ADD_ORGANIZATION, {
   tkc, organizationName, organizationCode, description,address1, address2, countryId, stateId, cityId, contactEmail, contactPhone1, contactPhone2, gstCinNo,
  domain, serviceMethod, serviceType, validTillDate, validFromDate, amount, usageLimit, website, userId, packageType
  })
}


export function editOrganization(tkc: string,organizationId:number, organizationName:string,organizationCode:string,description: string, 
  address1: string, address2: string, countryId: number, stateId: number, cityId: number,  contactEmail: string, contactPhone1: string,
  contactPhone2: string, gstCinNo: string, website: string, domain:string, serviceMethod: any, serviceType: number, validTillDate: any, 
  validFromDate: any, amount: number,  usageLimit: number, userId: number, packageType:number) {
  return axios.post(EDIT_ORGANIZATION, {
    tkc, organizationId, organizationName, organizationCode, description,address1, address2, countryId, stateId, cityId, contactEmail, 
    contactPhone1, contactPhone2, gstCinNo, website, domain,  serviceMethod, serviceType, validTillDate, validFromDate, amount, usageLimit, userId, packageType
  })
}

export function getPanCinNo(tkc: string, gstCinNo: string, organizationId:number) {
  return axios.post(GET_CIN_NO, {
   tkc, gstCinNo, organizationId
  })
}

export function getDomainName(tkc: string, domain: string, organizationId:number) {
  return axios.post(GET_DOMAIN_NAME, {
   tkc, domain, organizationId
  })
}


export function validateDomainName(tkc: string, domain: string, organizationId:number) {
  return axios.post(VALIDATE_DOMAIN_NAME, {
   tkc, domain, organizationId
  })
}
