import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { generatePass, requestPassword, validateToken } from '../redux/AuthCRUD'

const initialValues = {
  email: '',
}

interface IState {
  action: string,
  email: string | undefined,
  token: string | undefined,
  errorMessage: string,
  hasError : boolean
  showEmailStatus : boolean
}
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const history = useHistory();
    
  const [data, setData] = useState<IState>({
    action: 'SUBMIT_EMAIL',
    email: '',
    token: '',
    errorMessage: '',
    hasError : false,
    showEmailStatus:false
  })


  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
       
      if(data.action == "SUBMIT_EMAIL")
      {
        setTimeout(() => {
          generatePass(values.email)
            .then((response) => {
              let resultCode = response.data.responseCode
              if (resultCode == 0) {
                 
                setLoading(false)
                // history.push(`/auth/varify-token/${values.email}`)
              } else {
                 
                setLoading(false)
                setSubmitting(false)
                setStatus('The login detail is incorrect')
              }
              setData({
                ...data,
                action: 'VARIFICATION',
                email: values.email,
                showEmailStatus:true,
                hasError:false
              })
            })
            .catch((error) => {
              setData({
                ...data,
                action: 'VARIFICATION',
                showEmailStatus:true,
                hasError:false
              })
              //setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus('The login detail is incorrect')
            })
        }, 1000)
      }
      
    },
  })





  function verifyToken() {
    const token = document.getElementById('token') as HTMLInputElement;
    const email = document.getElementById('email') as HTMLInputElement;
   console.log("inside the verifytoken()")
    validateToken(email?.value, token?.value)
      .then((response) => {
        let resultCode = response.data.responseCode
        if (resultCode == 0) {
          /*  setData({
            ...data,
            email: data.email,
            token: data.token
          }) */
          alert("Please change your password");
          history.push(`/auth/varify-token/${email.value}/${token.value}`);
        } else {
          setData({
            ...data,
            //errorMessage: error.message,
            showEmailStatus:false,
            hasError:true
          })
          setLoading(true)
        }
      })
      .catch((error) => {
        setData({
          ...data,
          errorMessage: error.message,
          showEmailStatus:false,
          hasError:true
        })
      })
  }


  

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>
        {console.log("  action : "+data.action)}
        {/* begin::Title */}
        {  data.hasError   && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        { data.showEmailStatus ==true   && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset instructions to your registered email address. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            id='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        { data.action == "VARIFICATION" &&  (
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Token</label>
            <input
              type='text'
              placeholder=''
              id='token'
              autoComplete='off'
              {...formik.getFieldProps('token')}
              className='form-control form-control-lg form-control-solid'
            />
          </div>
        )}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            
            id='kt_password_reset_submit'
            disabled={formik.isSubmitting || !formik.isValid}
            className={data.action == 'SUBMIT_EMAIL' ? 'btn btn-lg btn-primary fw-bolder me-4' : 'd-none'}
          >
              {!loading && <span className='indicator-label'>Submit</span>} 
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            onClick={verifyToken}
            id='kt_password_reset_verify_token' type="button"
            className={data.action == 'VARIFICATION' ? 'btn btn-lg btn-primary fw-bolder me-4' : 'd-none'}
          >
            <span className='indicator-label'>Verify</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
