/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IUser } from '../../../../app/models/IUser'
import { getCountForUserDashboard } from '../../../../app/modules/Home/redux/UserDashboardCRUD'

interface IState {
  loading: boolean
  errorMessage: string
  //User
  selUser: IUser
  tExpirningSoonEnvelope: number
}
interface IProps { }

const HeaderUserMenu: FC = () => {
  const selector = useSelector
  const user: UserModel = selector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const tkc: string = user.tkc
  const organizationId: number = user.organizationId
  const userId: number = user.userId
  const history = useHistory();

  const dispatch = useDispatch()
  const logout = () => {
    localStorage.clear();
    dispatch(auth.actions.logout())
  }

  const [data, setData] = useState<IState>({
    loading: false,
    errorMessage: '',
    //user
    selUser: {} as IUser,
    tExpirningSoonEnvelope: 0,
  })

  useEffect(() => {
    (async () => {
      const data2 = await getCountForUserDashboard(tkc, organizationId, userId);
      // console.log("=========================> " + JSON.stringify(data2));
      setData({
        ...data,
        loading: false,
        tExpirningSoonEnvelope: data2.data.responseObject.countExpiringSoonEnv,
      })
    })()
  }, [])

  function myProfilePage() {
    history.push('/userDetails/profile')
  }

  function inboxPage() {
    history.push('/manage/inbox')
  }

  function sendPage() {
    history.push('/manage/sent')
  }

  function accountSettings() {
    history.push('/userDetails/updateProfile')
  }

  function changePassword() {
    history.push('/change/password')
  }

  let { loading, tExpirningSoonEnvelope } = data
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <Link to={`/app/account/${user.userId}/profile`}>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src="/media/logos/dark_logo.png" />
            </div>
          </Link>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.firstName} {user.lastName}
              {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <div className='menu-link px-5' onClick={() => myProfilePage()}>
          My Profile
        </div>
      </div>

      {/*  <div className='menu-link px-5' onClick={() => myProfilePage()}>
        Edit Profile
      </div>

      <div className='menu-item px-5' onClick={() => inboxPage()}>
        <a className='menu-link px-5'>
          <span className='menu-text' >My Envelopes</span>
          <span className='menu-badge'>
            <span className='badge badge-light-info badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

{/*       <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> 

      <div className='menu-item px-5' onClick={() => sendPage()}>
        <a className='menu-link px-5'>
          <span className='menu-text' > Expiring Soon</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>{tExpirningSoonEnvelope + ""}</span>
          </span>
        </a>
      </div>
*/}
      <Languages />

      <div className='menu-item px-5 my-1'>
        <div className='menu-link px-5' onClick={() => accountSettings()}>
          Account Settings
        </div>
        {/*  <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link> */}
      </div>

      <div className='menu-item px-5 my-1'>
        <div className='menu-link px-5' onClick={() => changePassword()}>
          Reset Password
        </div>
        {/*  <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link> */}
      </div>


      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
