import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import { UserModel } from '../models/UserModel'
import { IRole } from '../../../models/IRole'



import { getUserByToken } from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  responseModal: undefined,
  secureToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  responseModal?: any
  secureToken?: string
}

var strleftmenu = ''

export const reducer = persistReducer(
  { storage, key: 'user-info', whitelist: ['user', 'accessToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        let responseData = action.payload?.responseModal

        let resultObject = JSON.parse(responseData)

        console.log(`Result Object (AUTH) ${resultObject}`)
        const accessToken = resultObject['secureToken']
        let configList = resultObject['configs']
        let userObj = resultObject['userObj']
        console.log(`User Object (AUTH) ${userObj}`)
        if (userObj != undefined && userObj != null) {
          const userModel: UserModel = {
            tkc: resultObject['secureToken'],
            userId: userObj["userId"],
            username: userObj["emailAddress"],
            emailAddress: userObj["emailAddress"],            
            firstName: userObj["firstName"],
            lastName: userObj["lastName"],
            fullName: userObj["firstName"] + " " + userObj["lastName"],
            pic: "",
            isEmailAddressVerified: false,
            countryId: userObj["country"],
            userEntityId: userObj["userId"],            
            stateId: userObj["state"],
            userRole: "",
            organizationId: userObj["organizationId"],
            configs: userObj["configs"],
            userTypeId: userObj["userTypeId"],
            isDCAllow: userObj["isDCAllow"],
            isESAllow: userObj["isESAllow"],
            organizationName:userObj["organizationName"],
            cityName:resultObject['cityName'],
          };
          //localStorage.setItem("configsCode",strleftmenu);
          console.log("Configs =-====== before>>> " + localStorage.getItem('configs'));
          localStorage.setItem('configs', JSON.stringify(configList))
          console.log("Configs =-====== post>>> " + localStorage.getItem('configs'));

          return { accessToken, user: userModel }
        }
        else {
          const userModel: UserModel = {
            lastName: '',
            userId: 0,
            firstName: '',
            emailAddress: '',
            organizationId: 0,
            username: '',            
            tkc: '',
            configs: [],
            userTypeId: 0,
            isDCAllow: 0,
            isESAllow: 0,
            organizationName:'',
            cityName: '',
          };
          return { accessToken, user: userModel }
        }
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return { accessToken, user: undefined }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        let responseData = action.payload?.responseModal
        console.log("AuithRedux = " + JSON.stringify(responseData));

        let resultObject = responseData.responseObject;//JSON.parse(responseData)
        console.log("AuithRedux JSON= " + JSON.stringify(resultObject));

        // console.log('data11', resultObject)
        const accessToken = resultObject['secureToken']
        let configList = resultObject['configs']
        let userObj = resultObject['userObj']

        if (userObj != undefined && userObj != null) {
          const userModel: UserModel = {
            tkc: resultObject['secureToken'],
            userId: userObj["userId"],
            username: userObj["emailAddress"],
            emailAddress: userObj["emailAddress"],             
            firstName: userObj["firstName"],
            lastName: userObj["lastName"],
            fullName: userObj["firstName"] + " " + userObj["lastName"],
            pic: "",            
            isEmailAddressVerified: false,
            countryId: userObj["country"],
            userEntityId: userObj["userId"],            
            stateId: userObj["state"],
            userRole: "",
            organizationId: userObj["organizationId"],
            configs: userObj["configs"],
            userTypeId: userObj["userTypeId"],
            isDCAllow: userObj["isDCAllow"],
            isESAllow: userObj["isESAllow"],
            organizationName:userObj["organizationName"],
            cityName:resultObject['cityName'],
          };
          //localStorage.setItem("configsCode",strleftmenu);
          localStorage.setItem('configs', JSON.stringify(configList))
 
          // console.log(`accessToken : ${accessToken}`)
          return { accessToken, user: userModel, ...state, userModel }
        }
        else {
          const userModel: UserModel = {
            lastName: '',
            userId: 0,
            firstName: '',
            emailAddress: '',
            organizationId: 0,
            username: '',           
            tkc: '',
            configs: [],
            userTypeId: 0,
            isDCAllow: 0,
            isESAllow: 0,
            organizationName:'',
            cityName:''
          };
           
          return { accessToken, user: userModel, ...state, userModel }
        }

        
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (responseModal: string) => ({ type: actionTypes.Login, payload: { responseModal } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (responseModal: string) => ({ type: actionTypes.UserLoaded, payload: { responseModal } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  store: () => ({ type: 'def' }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken
    // @ts-ignore
    let token = yield select(getToken)
    const { data: user } = yield getUserByToken(token)
    yield put(actions.fulfillUser(user))
  })
}
