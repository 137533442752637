import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { IOrganizations } from '../../../../app/models/IOrganizations'
import { string } from 'yup/lib/locale'

export function AsideMenuMain() {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selector = useSelector
  const user: UserModel = selector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  var org:IOrganizations = JSON.parse(localStorage.getItem("refOrganization")!);

  var listLeftMenu = localStorage.getItem('configs')
 
  let tmpconflst = new String(listLeftMenu?.toString())
  //const lftmenu = ""
  function CheckConfig(strConfigname: string) {
    return tmpconflst.indexOf(strConfigname)
  }

  return (
    <>
      {/* for (let k1 in listLeftMenu)
        {
         // console.log(`configObj : ${JSON.stringify(configList[k1].length)}`)
       //  console.log(`configObj1 : ${JSON.stringify(configList[k1].configCode)}`)
       alert({listLeftMenu}.toString().indexOf("WALL"));
        } */}
        <div className= 'showleftmenu'>
            <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD_RIGHTS' })}
            fontIcon='bi-app-indicator' 
          />
        </div>
      
        <div className= 'hideleftmenu'>
            <AsideMenuItemWithSub
            to='/dashboard1'
            title={intl.formatMessage({ id: 'MENU_DASHBOARD' })}
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem to='/dashboard1/productAdmin' title='Product Admin' hasBullet={true} />
            <AsideMenuItem to='/dashboard/admin' title='Admin' hasBullet={true} />
            <AsideMenuItem to='/dashboard1/hod' title='HOD' hasBullet={true} />
            <AsideMenuItem to='/dashboard1/manager' title='Manager' hasBullet={true} />
          </AsideMenuItemWithSub>
        </div>

      
      {/* <AsideMenuItem 
        to='/builders'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>



      <div className='showleftmenu'>
        <AsideMenuItemWithSub
          to='/masters'
          icon='/media/icons/duotune/general/gen019.svg'
          title={intl.formatMessage({ id: 'MENU.MASTERS' })}
          fontIcon='bi-layers'
        >
          <div className={org.isBaseClient==1  && user.userTypeId == 1 ? 'showleftmenu' : 'hideleftmenu'}>
            <AsideMenuItem
              to='/apps/organizations'
              title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_ORG' })}
              hasBullet={true}  
            />
          </div>
          
          <div className={CheckConfig('MASTER-MANAGE_DEPARTMENT') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
              <AsideMenuItem
                to='/apps/departments'
                title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_DEPT' })}
                hasBullet={true}
              />
            </div>
          
            <div className={CheckConfig('MASTER-MANAGE_SUB_DEPARTMENT') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
              <AsideMenuItem
              to='/apps/sub-departments/'
              title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_SUB_DEPT' })}
              hasBullet={true}
            />
            </div>
            <div className={CheckConfig('MASTER-MANAGE_DESIGNATION') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
              <AsideMenuItem
              to='/apps/designations'
              title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_DESIG' })}
              hasBullet={true}
            />
            </div>
            <div className={CheckConfig('MASTER-MANAGE_USER') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
                  <AsideMenuItem
                  to='/apps/users'
                  title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_USER' })}
                  hasBullet={true}
                />
            </div>
            <div className={CheckConfig('MASTER-MANAGE_ROLE') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
            <AsideMenuItem
            to='/apps/roles'
            title={intl.formatMessage({ id: 'MENU.MASTER.MANAGE_ROLES' })}
            hasBullet={true}
          />
            </div>
            <div className={CheckConfig('MASTER-MANAGE_RIGHTS') > -1 ? 'showleftmenu' : 'hideleftmenu'}>
            <AsideMenuItem
            to='/apps/rights'
            title={intl.formatMessage({ id: 'MENU.MASTER.ASSIGN_RIGHTS' })}
            hasBullet={true}
          />
            </div>
          
          
          
        </AsideMenuItemWithSub>
      </div>

      {/* Home Page */}

      <AsideMenuItemWithSub
        to='/home'
        title={intl.formatMessage({ id: 'MENU.HOME' })}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen001.svg'
      >
        <AsideMenuItem to='/home/user' title='My Home' hasBullet={true} />
        <AsideMenuItem to='/home/docUpload' title='Documents' hasBullet={true} />

      </AsideMenuItemWithSub>


      {/* Dashboard */}

      
      {/* Manage Page */}
      <AsideMenuItemWithSub
        to='/manageMenu'
        title={intl.formatMessage({ id: 'MENU.MANAGE' })}
        fontIcon='manage'
        icon='/media/icons/duotune/general/gen032.svg'
      >
        <AsideMenuItem to='/manage/inbox' title='Inbox' hasBullet={true} />
        <AsideMenuItem to='/manage/sent' title='Sent' hasBullet={true} />
        <AsideMenuItem to='/manage/draft' title='Draft' hasBullet={true} />
        <AsideMenuItem to='/manage/deleted' title='Deleted' hasBullet={true} />
        <AsideMenuItem to='/manage/actionRequired' title='Action Required' hasBullet={true} />
        <AsideMenuItem to='/manage/ExpiringSoon' title='Expirng Soon' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* Manage Page */}
      <AsideMenuItemWithSub
        to='/reports'
        title={intl.formatMessage({ id: 'MENU.REPORT_RIGHTS' })}
        fontIcon='manage'
        icon='/media/icons/duotune/general/gen032.svg'
      >
         
            {/**} <AsideMenuItem to='/manage/inbox' title='All' hasBullet={true} />{**/}
          <AsideMenuItem to='/report/envelopes/' title='Envelope' hasBullet={true} />
          <AsideMenuItem to='/report/reciepients/' title='Recipient' hasBullet={true} />
        {/**}  <AsideMenuItem to='/manage/deleted' title='Usage' hasBullet={true} /> {**/}
         
          
         
      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/settings'
        title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
        fontIcon='manage'
        icon='/media/icons/duotune/general/gen032.svg'
      >
          <AsideMenuItem to='/userDetails/profile' title='Profile' hasBullet={true} />
          <AsideMenuItem to='/apps/languages' title='Language' hasBullet={true} /> 
          <AsideMenuItem to='/apps/contacts' title='Address Book' hasBullet={true} />            
      </AsideMenuItemWithSub>
      
   {/*    <AsideMenuItemWithSub
        to='/crafted/pages'
        title={intl.formatMessage({ id: 'MENU.PAGES' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}

      {/*   <AsideMenuItemWithSub
        to='/userDetails'
        title={intl.formatMessage({id: 'MENU.USER.PROFILE_PAGE'})}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/userDetails/profile' title={intl.formatMessage({id: 'MENU.USER.PROFILE_PAGE'})} hasBullet={true} />
        <AsideMenuItem to='/userDetails/update_profile' title={intl.formatMessage({id: 'MENU.USER.UPDATE.PROFILE_PAGE'})} hasBullet={true} />
      </AsideMenuItemWithSub>
 */}

    {/*   <AsideMenuItemWithSub
        to='/crafted/accounts'
        title={intl.formatMessage({ id: 'MENU.ACCOUNTS' })}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
     */}
     {/*  <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem
          to='/error/404'
          title={intl.formatMessage({ id: 'MENU.ERROR_404' })}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/error/500'
          title={intl.formatMessage({ id: 'MENU.ERROR_500' })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}

  {/*}    <AsideMenuItemWithSub
        to='/crafted/widgets'
        title={intl.formatMessage({ id: 'MENU.WIDGETS' })}
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
    </AsideMenuItemWithSub>  {*/}

 {/*      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title={intl.formatMessage({ id: 'MENU.CHAT' })}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
