import axios from 'axios'

declare module 'axios' {
  export interface AxiosRequestConfig {
    tkc?: string;
    userId?: number;
  }
}

const API_URL = process.env.REACT_APP_API_URL

export const GET_COUNT_DASHBOARD= `${API_URL}/v1/api/userdash/get/count`

export function getCountForUserDashboard(tkc: string, organizationId: number, userId: number) {
  return axios.post(GET_COUNT_DASHBOARD, {
    tkc, organizationId, userId
  })
}

